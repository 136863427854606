/** @jsx jsx */
import * as React from "react";
import { useState } from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { colors, screenSize } from "../utils/css/themes";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import People from "../utils/images/HomePage/ServiceHub/heart-five.inline.svg";
import LinkBuilder from "../components/ServiceHub/LinkBuilder";
import Caret from "../utils/images/Nav/icon-carat.inline.svg";

// 3rd party import
import Layout from "../components/layout";
import SEO from "../components/seo";
import Accordion from "../components/Helpers/Accordion";
import { css, jsx } from "@emotion/react";
import DangerousHtml from "../components/Helpers/DangerousHtml";

const ServiceHubOverview = () => {
  const [toggleOpen, setToggleOpen] = useState(0);
  const SearchLinksContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media (max-width: ${screenSize.largeTablet}) {
      flex-direction: column;
    }
  `;
  const SearchLink = styled((props) => <Link {...props} />)`
    border-radius: 5px;
    padding: 3rem 0;
    margin: 1rem 0;
    width: 45%;
    text-decoration: none;
    text-align: center;
    color: ${colors.blue};
    box-shadow: 4px 10px 20px 0 rgba(0, 0, 0, 0.2);
    @media (max-width: ${screenSize.largeTablet}) {
      width: 100%;
      margin: 0;
    }
    &:focus,
    &:hover {
      color: white;
      background-color: ${colors.blue};
    }
  `;
  const LeftHeadingContainer = styled.div`
    display: none;
    height: fit-content;

    @media (min-width: ${screenSize.largeTablet}) {
      display: inline-block;
      margin: 2rem 1.5rem 1rem 3rem;
      float: left;
      border-right: solid 1px ${colors.lightGrey};
      flex-direction: column;
      text-align: center;
      align-items: center;
      display: flex;
      padding-right: 3.5rem;
      max-width: 350px;
    }
  `;
  const AccordionTitle = styled.h2`
    margin-left: 5rem;
    margin-top: auto;
    margin-bottom: auto;
    color: ${colors.blue};
    font-family: Baloo;
    letter-spacing: -0.43px;

    @media (min-width: ${screenSize.smallTablet}) {
      font-size: 25px;
      margin-left: 2rem;
      text-align: left;
      width: 55%;
    }
    @media (min-width: ${screenSize.largeTablet}) {
      font-size: 26px;
      margin-left: 2rem;
    }
    @media (min-width: ${screenSize.largeDisplay}) {
      font-size: 26px;
    }
    @media (min-width: ${screenSize.smallPhone}) {
      margin-left: 1rem;
      width: 65%;
      text-align: left;
    }
  `;
  const CaretTitleContainer = styled.div(
    ({ i, toggleOpen }) => `
    	background-color: ${i === toggleOpen ? "#f1f2f6" : "white"};
    	display: flex;
    	width: 100%;
    	padding: 2rem;
    	justify-content: space-between;
    	@media (min-width: ${screenSize.smallTablet}) {
      	margin-bottom: 0;
      	padding: 0;
    	}
  	`
  );
  const CaretContainerStyle = styled.span(
    ({ i, toggleOpen }) => `
			background-color: ${i === toggleOpen ? colors.blue : "white"};
      border: ${i === toggleOpen ? "none" : "solid 2px " + colors.orange};
      @media (min-width: ${screenSize.smallTablet}) {
      	border: ${i === toggleOpen ? "none" : "solid 3px " + colors.orange};
				}
			margin-left: auto;
			margin-right: 1rem;
			display: flex;
			border-radius: 100px;
			margin-top: auto;

			width: 28px;
			height: 28px;
			margin-bottom: auto;

			@media (min-width: ${screenSize.smallTablet}) {
				width: 58px;
				height: 58px;
				margin-right: 2rem;
			}
		`
  );
  const ImageContainer = styled.div`
    margin: 2rem 0 0 0;
  `;
  const ImageContainerAccordion = styled.div`
    border-top-left-radius: 5px;
    @media (min-width: ${screenSize.smallTablet}) {
    }
  `;

  const LocalFileImgCSS = styled((props: any) => <GatsbyImage {...props} />)`
    display: list-item !important;
    @media (min-width: ${screenSize.smallTablet}) {
      min-width: 298px;
    }

    min-height: 128px;
    max-height: 128px;
  `;

  const SourceUrlImage = styled.img`
    display: list-item !important;
    @media (min-width: ${screenSize.smallTablet}) {
      min-width: 298px;
    }
    min-height: 128px;
    max-height: 128px;
    object-fit: cover;
  `;
  const LinksContainer = styled.div`
    border-left: none;
    width: 100%;
    margin: 1.5rem 0 0 1rem;

    float: left;
    min-height: 247px;

    @media (min-width: ${screenSize.smallTablet}) {
      max-height: 450px;
      margin: 1.5rem 0 0 2rem;
    }
    @media (min-width: ${screenSize.largeTablet}) {
      max-height: 300px;
      margin: 1.5rem;
    }
  `;
  const AccordionContainer = styled.div`
    padding: 0 1.5rem;
    @media (min-width: ${screenSize.largeDisplay}) {
      padding: 0;
    }
  `;

  const serviceHub = useStaticQuery(graphql`
    {
      wpPage(title: { eq: "Service Hub" }) {
        ...ServiceHubPages
      }
      allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Issue Page" } } } }
        }
        sort: { order: ASC, fields: title }
      ) {
        nodes {
          title
          slug
          issuePageType {
            issuePageType
          }
        }
      }
    }
  `);

  if (!serviceHub?.wpPage) {
    return (
      <Layout>
        <p>Could not find data for page: "Service Hub".</p>
      </Layout>
    );
  }

  const serviceHubPage = serviceHub?.wpPage;
  const help_types = serviceHubPage?.communityServiceHubFields?.helpTypes;
  const issue_pages = serviceHub?.allWpPost?.nodes;

  // Sort the issue_pages alphabetically by their "title" property.
  // NOTE: GraphQL should already be sorting alphabetically by title, however their is an intermittent
  // reoccuring bug, that sometimes applies the graphql sort when building, and
  // other times does not. I am hardcoding a sort in Javascript, to force the sort
  // everytime. -Christian Brooks
  issue_pages.sort(function (a: any, b: any) {
    var textA = a.title.toUpperCase();
    var textB = b.title.toUpperCase();
    return textA < textB ? -1 : textA > textB ? 1 : 0;
  });

  const Image = (): any => {
    return <People />;
  };

  const GenerateAccordion = () => {
    if (!help_types) {
      return (
        <p>
          Could not find data for "Community Service Hub Fields" on page
          "Service Hub".
        </p>
      );
    }
    return help_types.map((type: any, i: number) => {
      const title = (
        <React.Fragment>
          <ImageContainerAccordion>
            {type.image && type.image.localFile && (
              <LocalFileImgCSS
                alt={"accordion image"}
                image={type.image.localFile.childImageSharp.gatsbyImageData}
              />
            )}
            {!type.image.localFile && type.image.source_url && (
              <SourceUrlImage
                alt={"accordion image"}
                src={type.image.source_url}
              />
            )}
          </ImageContainerAccordion>
          <CaretTitleContainer i={i} toggleOpen={toggleOpen}>
            <AccordionTitle>
              Ways to Help {type.type.toUpperCase()}
            </AccordionTitle>
            <CaretContainerStyle i={i} toggleOpen={toggleOpen}>
              <Caret
                height={"20"}
                width={"20"}
                css={css`
                  transform: ${i === toggleOpen ? "rotate(-180deg)" : "none"};

                  path,
                  g {
                    stroke: ${i === toggleOpen ? "white" : colors.orange};
                    stroke-width: 1;
                  }
                  margin: auto;
                `}
              >
                &and;
              </Caret>
            </CaretContainerStyle>
          </CaretTitleContainer>
        </React.Fragment>
      );
      return (
        <Accordion
          key={i}
          isOpen={i === toggleOpen}
          setIsOpen={() =>
            i !== toggleOpen ? setToggleOpen(i) : setToggleOpen(null)
          }
          title={title}
          isFullWidth={true}
        >
          <LeftHeadingContainer>
            <ImageContainer>{Image()}</ImageContainer>
            <DangerousHtml>{help_types[i].description}</DangerousHtml>
          </LeftHeadingContainer>
          <LinksContainer>
            <LinkBuilder
              from_service_hub={true}
              issue_pages={issue_pages}
              type={type}
            />
          </LinksContainer>
        </Accordion>
      );
    });
  };

  return (
    <Layout
      title={serviceHubPage.title}
      wordPressContent={serviceHubPage.content}
    >
      <SEO title="Service Hub" />
      <DangerousHtml>{serviceHub?.content}</DangerousHtml>
      <AccordionContainer>
        <div>{GenerateAccordion()}</div>
      </AccordionContainer>
      <SearchLinksContainer>
        <SearchLink to="/search-for-an-organization/search">
          {"Find an Organization to Support"}
        </SearchLink>
        <SearchLink to="/find-a-service-project/search">
          {"Find a Service Project"}
        </SearchLink>
      </SearchLinksContainer>
    </Layout>
  );
};

export default ServiceHubOverview;
